@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
*, *::after, *::before {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

* {
    position: relative;
}

html {
    font-size: 50%; 
    box-sizing: border-box;
}  

@media (min-width: 500px) {
    html {
        font-size: 62.5%;
    }
}

body {
    font-size: 16px; /* px fallback */
    font-size: 1.6rem; /* default font-size for document */
    line-height: 1.5; /* a nice line-height */
    background-color: #191919;
    font-family: 'Roboto', sans-serif;
}
